<template>
  <div>
    <div class="product-detail">
        <a href="/">返回首页</a>
        <div class="title-header">
            <div class="header-img">
                <img src="../../components/images/mechanic.png">
            </div>
            <div class="header-info">
                <div class="software-name">同城CALL技工版</div>
                <div class="software-company">恒臻科技（大连）有限责任公司</div>
            </div>
        </div>
        <div class="title-content">
            <div class="content-name">产品介绍</div>
            <div class="content-info">
                同城CALL技工版是由恒臻科技（大连）有限责任公司编写的一款APP应用，本应用致力于真实、高效、免费的本地服务，为专业技术人员提供一个良好的服务平台。<br/>
                技工在登录注册同城CALL技工版APP后，可以看到用户发布的所有家政需求内容，根据自身情况选择接收订单，上门为用户完成服务。并在服务结束后查看到用户对自身的评价信息。
            </div>
        </div>
        <div class="title-footer">
            <img src="../../components/images/m-screenshot/1.png">
            <img src="../../components/images/m-screenshot/2.png">
            <img src="../../components/images/m-screenshot/3.png">
            <img src="../../components/images/m-screenshot/4.png">
        </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CityCallMechanic',
  components: {},
  data(){
    return{
    }
  },
  mounted(){
  },
  methods:{
  }
}
</script>

<style scoped>
    .product-detail {
        width: 70vw;
        margin: 0px 15vw;
        padding: 30px 60px;
        min-width: 400px;
        min-height: 400px;
        background-color: #F0F8FF;
    }

    .title-header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 200px;
    }

    .header-img {
        width: 120px;
        height: 120px;
    }

    .header-img img {
        width: 120px;
        height: 120px;
        border-radius: 20px;
    }

    .header-info {
        width: calc(100% - 240px);
        margin: 0px 0px 0px 20px;
    }

    .software-name {
        font-size: 24px;
        font-weight: bold;
    }

    .software-company {
        font-size: 16px;
        color: dimgray;
    }

    .title-content {
        width: calc(100% - 120px);
        height: 200px;
        padding: 10px 60px;
    }

    .content-name {
        font-size: 18px;
        font-weight: bold;
    }

    .content-info {
        margin: 10px 0px 0px 0px;
        font-size: 14px;
    }

    .title-footer {
        width: 100%;
        height: 260px;
        padding: 0px 60px;
    }

    .title-footer img {
        width: 135px;
        height: 240px;
        margin: 0px 20px;
        border: 1px dashed #999;
    }
</style>
